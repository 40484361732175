import {ReactComponent as Stats} from '../svgs/stats.svg';

export default function Why() {
    return (
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative before:content-['👉'] before:mr-3">
                <h1 className="font-heading text-center mb-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl ">Hvorfor?</h1>

            </div>

            <Stats />
        </section>
    )
}
