
import React from 'react';
import Header from './components/Header';
import Who from './components/Who';
import Hero from './components/Hero';
import Why from './components/Why';


function App() {
  return (
    <>
      <Header />
      <Hero />
      <Why />
    </>
  );
}

export default App;
