import {ReactComponent as HeroIlu} from '../svgs/hero.svg';
import Button from './Button.js'

export default function Hero() {
    return (
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
            <div className="md:flex ">

                <HeroIlu className="animate-floating md:order-last" />

                <div className="w-full flex flex-col justify-center items-center md:order-first">
                    <h1 className="font-heading text-center mb-4 sm:text-xl md:text-2xl lg:text-3xl">Nå ud til dine kunder med Facebook og Instagram annoncering</h1>
                    <Button />
                </div>
            </div>
        </section>
    )
}
