import React from 'react'

export default function Button() {
    return (
        <button className=" text-white font-body px-6 py-2 bg-blue-600 block rounded-md hover:bg-blue-400 transition-colors ease-in-out duration-300">
            Kontakt
        </button>
    )
}

